const getters = {
    rowNumber: state => state.settings.rowNumber,
    sidebar: state => state.app.sidebar,
    /*
    size: state => state.app.size,
    device: state => state.app.device,  
    introduction: state => state.user.introduction,
    permission_routes: state => state.permission.routes,
    errorLogs: state => state.errorLog.logs
    visible: state => state,
    idle: state => state.activity.idle,
   */
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    avatar_notification: state => state.user.avatar_notification,
    name: state => state.user.name,
    roles: state => state.user.roles,
    menu: state => state.user.menu,
    permissions: state => state.user.permissions,
}
export default getters
