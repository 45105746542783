import { find, sortBy, map, filter } from "lodash/core";
import pickBy from "lodash/pickBy";
import cloneDeep from "lodash/cloneDeep";
import intersection from "lodash/intersection";
import startsWith from "lodash/startsWith";
//import throttle from 'lodash/throttle';
import debounce from "lodash/debounce";
import remove from "lodash/remove";
//import keys from "lodash/keys";
//window._ = require('lodash');
window._ = {
    cloneDeep,
    find,
    startsWith,
    pickBy,
    sortBy,
    map,
    intersection,
    //    , throttle
    debounce,
    filter,
    remove,

};
window.downloadLogo = function(logo) {
    if (logo) {
        encodeImage("/files/" + logo, function(e, w, h) {
            localStorage.setItem("logo", e);
            localStorage.setItem("logo_w", w);
            localStorage.setItem("logo_h", h);
        });
    } else {
        localStorage.setItem("logo", null);
        localStorage.setItem("logo_w", 1);
        localStorage.setItem("logo_h", 1);
    }
};

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

//try {
//window.Popper = require('popper.js').default;
// window.$ = window.jQuery = require('jquery');
//require('bootstrap');
//} catch (e) {}




import moment from "moment";
import "moment/locale/es";
window.moment = moment;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/**
 * agregamos algunas funciones personalizadas
 */
String.prototype.hashCode = function() {
    var hash = 0,
        i,
        chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

window.objectToArray = function(o) {
    let arr = [];
    for (let i in o) {
        if (i[0] != "_") {
            arr.push(o[i]);
        }
    }
    return arr;
};

String.prototype.plainStr = function() {
    return this.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .replace(/\s+/g, " ")
        .trim();
};
window.sha1=async function (texto) {
    const codificacion = new TextEncoder();
    const datos = codificacion.encode(texto);
    const hashBuffer = await crypto.subtle.digest('SHA-1', datos);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }
     
window.mobilecheck = function() {
    var check = false;
    (function(a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

//import moment from "moment";
//import moment from "moment/src/moment";

Date.prototype.diff = function(date) {
    return Math.abs((this.getTime() - date.getTime()) / 1000);
};

Date.prototype.getDateStr = function(onlyNumber) {
    if (onlyNumber)
        return (
            this.getFullYear() +
            "-" +
            ("00" + (this.getMonth() + 1)).substr(-2) +
            "-" +
            ("00" + this.getDate()).substr(-2)
        );
    else
        return (
            this.getFullYear() +
            "-" +
            ("00" + (this.getMonth() + 1)).substr(-2) +
            "-" +
            ("00" + this.getDate()).substr(-2)
        );
};
Date.prototype.getTimeStr = function() {
    return (
        ("00" + this.getHours()).substr(-2) +
        ":" +
        ("00" + this.getMinutes()).substr(-2) +
        ":" +
        ("00" + this.getSeconds()).substr(-2)
    );
};
Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

window.now = function(withoutTime) {
    let d = new Date();
    if (!withoutTime) {
        return d.getDateStr() + " " + d.getTimeStr();
    } else {
        return d.getDateStr();
    }
};

window.dateFormatddmmYY = function(date) {
    let date_array = date.toISOString().split(/[^0-9]/);
    return date_array[2]+"-"+date_array[1]+"-"+date_array[0]
return day + "-" + month + "-" + year;

    // let date_array = date.toLocaleDateString().split("/");

    // let year = date_array[2];
    // let month = String(date_array[1]).padStart(2, "0");
    // let day = String(date_array[0]).padStart(2, "0");

    // return day + "-" + month + "-" + year;
};

window.dateFormatYYmmdd = function (date) {
    return date.toISOString().substr(0, 10);
    // let date_array = date.toLocaleDateString().split("/");

    // let year = date_array[2];
    // let month = String(date_array[1]).padStart(2, "0");
    // let day = String(date_array[0]).padStart(2, "0");

    // return year + "-" + month + "-" + day;
};

window.isNumeric = function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

Number.prototype.formatMoney = function(c, d, t) {
    var n = this,
        c = isNaN((c = Math.abs(c))) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return (
        s +
        (j ? i.substr(0, j) + t : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : "")
    );
};

window.debug = function(obj, title, content) {
    console.info(obj, title, content);
};

window.getSavedVariable = function(key, defaultValue) {
    let tmpValue = localStorage.getItem(key);
    try {
        if (tmpValue) return JSON.parse(tmpValue);
    } catch (e) {
        console.error("getSavedVariable", key, tmpValue);
    }
    return defaultValue;
};

window.setSavedVariable = function(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
};

window.is_callable = function(mixedVar, syntaxOnly, callableName) {
    // eslint-disable-line camelcase
    //  discuss at: https://locutus.io/php/is_callable/
    //  discuss at: https://locutus.io/php/is_callable/
    // original by: Brett Zamir (https://brett-zamir.me)
    //    input by: François
    // improved by: Brett Zamir (https://brett-zamir.me)
    // improved by: KnightYoshi
    // improved by: Waldo Malqui Silva (https://fayr.us/waldo/)
    //      note 1: The variable callableName cannot work as a string variable passed by
    //      note 1: reference as in PHP (since JavaScript does not support passing
    //      note 1: strings by reference), but instead will take the name of
    //      note 1: a global variable and set that instead.
    //      note 1: When used on an object, depends on a constructor property
    //      note 1: being kept on the object prototype
    //      note 2: Depending on the `callableName` that is passed, this function can use eval.
    //      note 2: The eval input is however checked to only allow valid function names,
    //      note 2: So it should not be unsafer than uses without eval (seeing as you can)
    //      note 2: already pass any function to be executed here.
    //   example 1: is_callable('is_callable')
    //   returns 1: true
    //   example 2: is_callable('bogusFunction', true)
    //   returns 2: true // gives true because does not do strict checking
    //   example 3: function SomeClass () {}
    //   example 3: SomeClass.prototype.someMethod = function (){}
    //   example 3: var testObj = new SomeClass()
    //   example 3: is_callable([testObj, 'someMethod'], true, 'myVar')
    //   example 3: var $result = myVar
    //   returns 3: 'SomeClass::someMethod'
    //   example 4: is_callable(function () {})
    //   returns 4: true
    //   example 5: is_callable(class MyClass {})
    //   returns 5: false
    const $global = typeof window !== "undefined" ? window : global;
    const validJSFunctionNamePattern = /^[_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*$/;
    let name = "";
    let obj = {};
    let method = "";
    let validFunctionName = false;
    const getFuncName = function(fn) {
        const name = /\W*function\s+([\w$]+)\s*\(/.exec(fn);
        if (!name) {
            return "(Anonymous)";
        }
        return name[1];
    };
    // eslint-disable-next-line no-useless-escape
    if (/(^class|\(this\,)/.test(mixedVar.toString())) {
        return false;
    }
    if (typeof mixedVar === "string") {
        obj = $global;
        method = mixedVar;
        name = mixedVar;
        validFunctionName = !!name.match(validJSFunctionNamePattern);
    } else if (typeof mixedVar === "function") {
        return true;
    } else if (
        Object.prototype.toString.call(mixedVar) === "[object Array]" &&
        mixedVar.length === 2 &&
        typeof mixedVar[0] === "object" &&
        typeof mixedVar[1] === "string"
    ) {
        obj = mixedVar[0];
        method = mixedVar[1];
        name =
            (obj.constructor && getFuncName(obj.constructor)) + "::" + method;
    }
    if (syntaxOnly || typeof obj[method] === "function") {
        if (callableName) {
            $global[callableName] = name;
        }
        return true;
    }
    // validFunctionName avoids exploits
    if (validFunctionName && typeof eval(method) === "function") {
        // eslint-disable-line no-eval
        if (callableName) {
            $global[callableName] = name;
        }
        return true;
    }
    return false;
};

window.uniqid=function(prefix = "", random = false) {
    const sec = Date.now() * 1000 + Math.random() * 1000;
    const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
};

window.remoteUrl='../api/v0/planillas/'
//window.remoteUrl='../pl/public/'






window.hideTmpDlg=function(id){
	document.getElementsByClassName('tempDialog')[0].classList.remove('show')
	setTimeout(function(){
		document.getElementById('td_'+id).remove()
	},750)
}

window.verDialogo=function(clase, titulo, contenido) {
  // crea un contenedor para el diálogo
var dialogo = document.createElement('div');
dialogo.className = 'modal fade tempDialog';
dialogo.style = 'display:block';

	var random_id=Math.round( Math.random()*10000)

dialogo.innerHTML = '<div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h4 class="modal-title"></h4><button type="button" onclick="hideTmpDlg('+random_id+')" class="close" data-dismiss="modal">&times;</button></div><div class="modal-body"></div></div></div>';

  // agrega la clase de Bootstrap correspondiente al diálogo
  dialogo.classList.add('alert-' + clase);
  dialogo.getElementsByClassName("modal-header")[0].classList.add('btn-' + clase);
  // agrega el título al diálogo
  dialogo.getElementsByClassName("modal-title")[0].textContent=titulo
	dialogo.id='td_'+random_id
  // agrega el contenido al diálogo
  var $contenido =dialogo.getElementsByClassName('modal-body')[0]
  if (Array.isArray(contenido)) {
    // si el contenido es un array, crea un div para cada texto
    contenido.forEach(function(texto) {
		$contenido.innerHTML+="<div>"+texto+"</div>"
    });
  } else {
    // si el contenido es un texto simple, agrega el texto directamente
		$contenido.innerHTML="<div>"+texto+"</div>"
  }

  // muestra el diálogo
  //document.body.insertAdjacentHTML('beforeend',dialogo)
  document.body.append(dialogo)
  	document.getElementsByClassName('tempDialog')[0].classList.add('show')

}