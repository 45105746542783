import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRoutes = [
           // {
           //     path: '/home',
           //     name: 'home',
           //     component: () => import('./views/Home')
           // }
           // {
           //     path: '/prueba',
           //     name: 'prueba',
           //     component: () => import('./views/Pruebas')
           //
           {
               path: "/",
               name: "home",
               component: () => import("@/views/Home"),
               meta: {
                   title: "Inicio",
                   icon: "home",
                   roles: [],
                   permissions: []
               }
           },
           {
               path: "/test1",
               name: "home",
               component: () => import("@/views/Home"),
               meta: {
                   title: "Inicio",
                   icon: "home",
                   roles: ["noexistente"],
                   permissions: []
               }
           },

           {
               path: "/redirect",
               component: () => import("@/views/Redirect"),
               hidden: true,
               children: [
                   {
                       path: "/redirect/:path*",
                       component: () => import("@/views/Redirect")
                   }
               ]
           },
           {
               path: "/login",
               component: () => import("@/views/Home"),
               hidden: true
           },
           /*
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true
    },
    */
           {
               path: "/auth-redirect",
               component: () => import("@/views/login/auth-redirect"),
               hidden: true
           },
           {
               path: "/404",
               component: () => import("@/views/error-page/404"),
               hidden: true,
               name: "404"
           },
           {
               path: "/401",
               component: () => import("@/views/error-page/401"),
               hidden: true,
               name: "401"
           },
           {
               path: "/mi_asistencia",
               component: () => import("@/views/AsistenciaUsuario"),
               hidden: true,
               name: "mi_asistencia",
               meta: {
                   title: "Mi Asistencia",
                   icon: "user",
                   roles: [],
                   permissions: ["mi_usuario"]
               }
           },

           {
               path: "/mis_permisos",
               component: () =>
                   import("@/views/SolicitudesJustificacionUsuario"),

               name: "mis_permisos",
               meta: {
                   title: "Mis Solicitudes de Papeleta",
                   icon: "clipboard",
                   roles: [],
                   permissions: ["mi_usuario"]
               }
           },
           {
               path: "/perfil",
               component: () => import("@/views/profile/index"),
               name: "perfil",
               meta: { title: "Perfil de Usuario", icon: "clipboard" }
           },
           {
               path: "/persona",
               name: "persona",
               component: () => import("@/views/Persona"),
               meta: {
                   title: "Persona",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "persona_listar",
                       "persona_nuevo",
                       "persona_editar",
                       "persona_borrar",
                       "persona_ver",
                       "persona_busqueda",
                       "persona_exportar"
                   ]
               }
           },
           {
               path: "/modalidad",
               name: "modalidad",
               component: () => import("@/views/Modalidad"),

               meta: {
                   title: "Modalidades",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "modalidad_listar",
                       "modalidad_nuevo",
                       "modalidad_editar",
                       "modalidad_borrar",
                       "modalidad_ver",
                       "modalidad_busqueda",
                       "modalidad_exportar"
                   ]
               }
           },
           {
               path: "/documento",
               name: "documento",
               component: () => import("@/views/Documento"),

               meta: {
                   title: "Documento",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "documento_listar",
                       "documento_nuevo",
                       "documento_editar",
                       "documento_borrar",
                       "documento_ver",
                       "documento_busqueda"
                   ]
               }
           },
           {
               path: "/regimen",
               name: "regimen",
               component: () => import("@/views/Regimen"),

               meta: {
                   title: "Régimen",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "regimen_listar",
                       "regimen_nuevo",
                       "regimen_editar",
                       "regimen_borrar",
                       "regimen_ver",
                       "regimen_busqueda",
                       "regimen_exportar"
                   ]
               }
           },
           {
               path: "/escala",
               name: "escala",
               component: () => import("@/views/Escala"),

               meta: {
                   title: "Escala",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "escala_listar",
                       "escala_nuevo",
                       "escala_editar",
                       "escala_borrar",
                       "escala_ver",
                       "escala_busqueda"
                   ]
               }
           },
           {
                path: "/escala_obra",
                name: "escala_obra",
                component: () => import("@/views/EscalaObra"),

                meta: {
                    title: "Escala Obra",
                    icon: "lock",
                    roles: ["admin"],
                    permissions: [
                        "escala_listar",
                        "escala_nuevo",
                        "escala_editar",
                        "escala_borrar",
                        "escala_ver",
                        "escala_busqueda"
                    ]
                }
            },
           {
               path: "/sede",
               name: "sede",
               component: () => import("@/views/Sede"),

               meta: {
                   title: "Sedes",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "sede_listar",
                       "sede_nuevo",
                       "sede_editar",
                       "sede_borrar",
                       "sede_ver",
                       "sede_busqueda",
                       "sede_exportar"
                   ]
               }
           },
           {
               path: "/afp",
               name: "afp",
               component: () => import("@/views/Afp"),

               meta: {
                   title: "AFP",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "afp_listar",
                       "afp_nuevo",
                       "afp_editar",
                       "afp_borrar",
                       "afp_ver",
                       "afp_busqueda",
                       "afp_exportar"
                   ]
               }
           },
           {
               path: "/banco",
               name: "banco",
               component: () => import("@/views/Banco"),

               meta: {
                   title: "Bancos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "banco_listar",
                       "banco_nuevo",
                       "banco_editar",
                       "banco_borrar",
                       "banco_ver",
                       "banco_busqueda",
                       "banco_exportar"
                   ]
               }
           },
           {
               path: "/campaña",
               name: "campagna",
               component: () => import("@/views/Campagna"),

               meta: {
                   title: "Campañas",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "campagna_listar",
                       "campagna_nuevo",
                       "campagna_editar",
                       "campagna_borrar",
                       "campagna_ver",
                       "campagna_busqueda",
                       "campagna_exportar"
                   ]
               }
           },
           {
               path: "/puesto",
               name: "puesto",
               component: () => import("@/views/Puesto"),

               meta: {
                   title: "Puestos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "puesto_listar",
                       "puesto_nuevo",
                       "puesto_editar",
                       "puesto_borrar",
                       "puesto_ver",
                       "puesto_busqueda",
                       "puesto_exportar"
                   ]
               }
           },
           {
               path: "/rango",
               name: "rango",
               component: () => import("@/views/Rango"),

               meta: {
                   title: "Rangos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "rango_listar",
                       "rango_nuevo",
                       "rango_editar",
                       "rango_borrar",
                       "rango_ver",
                       "rango_busqueda",
                       "rango_exportar"
                   ]
               }
           },
           {
               path: "/reclutamiento",
               name: "reclutamiento",
               component: () => import("@/views/Reclutamiento"),

               meta: {
                   title: "Reclutamientos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "reclutamiento_listar",
                       "reclutamiento_nuevo",
                       "reclutamiento_editar",
                       "reclutamiento_borrar",
                       "reclutamiento_ver",
                       "reclutamiento_busqueda",
                       "reclutamiento_exportar"
                   ]
               }
           },

           {
               path: "/t_registro",
               name: "t_registro",
               component: () => import("@/views/TRegistro"),
               meta: {
                   title: "T-Registro",
                   icon: "tie",
                   roles: ["admin"],
                   permissions: [
                       "t_registro_listar",
                       "t_registro_nuevo",
                       "t_registro_editar",
                       "t_registro_ver",
                       "t_registro_periodo",
                       "t_registro_estudio_capacitacion",
                       "t_registro_experiencia_laboral",
                       "t_registro_cargo_confianza",
                       "t_registro_merito",
                       "t_registro_busqueda",
                       "t_registro_exportar"
                   ]
               }
           },
           {
               path: "/administrativos",
               name: "administrativos",
               component: () => import("@/views/Administrativo"),
               meta: {
                   title: "Administrativos",
                   icon: "tie",
                   roles: ["admin"],
                   permissions: [
                       "administrativo_listar",
                       "administrativo_agregar",
                       "administrativo_activar",
                       "administrativo_desactivar",
                       "administrativo_crear_justificacion",
                       "administrativo_editar",
                       "administrativo_ver",
                       "administrativo_ver_asistencia",
                       "administrativo_ver_planilla",
                       "administrativo_regimen_persona",
                       "administrativo_modalidad_persona",
                       "administrativo_cargo_persona",
                       "administrativo_escala_persona",
                       "administrativo_discapacidad_persona",

                       "administrativo_evaluacion",
                       "administrativo_experiencia_laboral",
                       "administrativo_capacitacion",
                       "administrativo_discapacidad",
                       "administrativo_habilidad",
                       "administrativo_aptitud",
                       "administrativo_estudio",
                       "administrativo_idioma",
                       "administrativo_merito",
                       "administrativo_demerito",
                       "administrativo_sancion",
                       "administrativo_regimen_aseguramiento",
                       "administrativo_regimen_pensionario",
                       "administrativo_sctr_salud",
                       "administrativo_certificado_habilidad",
                       "administrativo_bonificacion_familiar",

                       "administrativo_borrar",
                       "administrativo_busqueda",
                       "administrativo_exportar"
                   ]
               }
           },
           {
               path: "/personal",
               name: "personal",
               component: () => import("@/views/Personal"),

               meta: {
                   title: "Personal",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "personal_listar",
                       "personal_nuevo",
                       "personal_editar",
                       "personal_borrar",
                       "personal_ver",
                       "personal_busqueda",
                       "personal_listar_historico",
                       "personal_contrato",
                       "personal_restaurar",
                       "personal_supervisor",
                       "personal_discapacidad_persona",
                       "personal_exportar"
                   ]
               }
           },
           {
               path: "/persona_cuenta",
               name: "persona_cuenta",
               component: () => import("@/views/PersonaCuenta"),

               meta: {
                   title: "Cuenta",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "persona_cuenta_listar",
                       "persona_cuenta_nuevo",
                       "persona_cuenta_editar",
                       "persona_cuenta_borrar",
                       "persona_cuenta_ver",
                       "persona_cuenta_busqueda",
                       "persona_cuenta_exportar"
                   ]
               }
           },
           {
               path: "/pago",
               name: "pago",
               component: () => import("@/views/ComisionPagoFinal"),

               meta: {
                   title: "Pago",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "comision_pago_final_listar",
                       "comision_pago_final_nuevo",
                       "comision_pago_final_editar",
                       "comision_pago_final_borrar",
                       "comision_pago_final_ver",
                       "comision_pago_final_busqueda",
                       "comision_pago_final_exportar"
                   ]
               }
           },
           {
               path: "/secuencia_persona",
               name: "secuencia_persona",
               component: () => import("@/views/SecuenciaPersona"),

               meta: {
                   title: "Secuencia",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "secuencia_persona_listar",
                       "secuencia_persona_nuevo",
                       "secuencia_persona_editar",
                       "secuencia_persona_borrar",
                       "secuencia_persona_ver",
                       "secuencia_persona_busqueda",
                       "secuencia_persona_exportar"
                   ]
               }
           },
           {
               path: "/area",
               name: "area",
               component: () => import("@/views/Area"),

               meta: {
                   title: "Unidad Organica",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "area_listar",
                       "area_nuevo",
                       "area_editar",
                       "area_borrar",
                       "area_ver",
                       "area_busqueda",
                       "area_exportar"
                   ]
               }
           },
           {
               path: "/cargo",
               name: "cargo",
               component: () => import("@/views/Cargo"),

               meta: {
                   title: "Cargo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "cargo_listar",
                       "cargo_nuevo",
                       "cargo_editar",
                       "cargo_borrar",
                       "cargo_ver",
                       "cargo_busqueda",
                       "cargo_exportar"
                   ]
               }
           },
           {
               path: "/cargo_clasificado",
               name: "cargo_clasificado",
               component: () => import("@/views/CargoClasificado"),

               meta: {
                   title: "Cargo Clasificado",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "cargo_clasificado_listar",
                       "cargo_clasificado_nuevo",
                       "cargo_clasificado_editar",
                       "cargo_clasificado_borrar",
                       "cargo_clasificado_ver",
                       "cargo_clasificado_busqueda",
                       "cargo_clasificado_exportar"
                   ]
               }
           },
           {
               path: "/obra",
               name: "obra",
               component: () => import("@/views/Obra"),

               meta: {
                   title: "Obra/Proyecto",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "obra_listar",
                       "obra_nuevo",
                       "obra_editar",
                       "obra_borrar",
                       "obra_ver",
                       "obra_busqueda",
                       "obra_exportar"
                   ]
               }
           },
           //   {
           //   path: '/proyecto',
           //   name: 'proyecto',
           //   component: () => import('@/views/Proyecto')

           //   , meta: {
           //     title: 'Obra',
           //     icon: 'lock',
           //     roles: ['admin'],
           //     permissions: [
           //       'proyecto_listar',
           //       'proyecto_nuevo',
           //       'proyecto_editar',
           //       'proyecto_borrar',
           //       'proyecto_ver',
           //       'proyecto_busqueda',
           //       'proyecto_exportar',
           //     ]
           //   }
           // },
           {
               path: "/periodo_laboral",
               name: "contrato",
               component: () => import("@/views/Contrato"),

               meta: {
                   title: "Periodo Laborales",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "contrato_listar",
                       "contrato_nuevo",
                       "contrato_editar",
                       "contrato_borrar",
                       "contrato_ver",
                       "contrato_restaurar",
                       "contrato_busqueda",
                       "contrato_adenda",
                       "contrato_exportar"
                   ]
               }
           },
           {
               path: "/reporte_personal",
               name: "reporte_personal",
               component: () => import("@/views/ReportePersonal"),

               meta: {
                   title: "Reporte Personal",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "reporte_personal_exportar",
                       "reporte_personal_exportar_personal",
                       "reporte_personal_exportar_personal_asistencia",
                       "reporte_personal_exportar_personal_laboral",
                       "reporte_personal_exportar_personal_onomastico",
                       "reporte_personal_exportar_personal_pago",
                       "reporte_personal_exportar_personal_justificacion",
                       "reporte_personal_exportar_personal_proyecto",
                       "reporte_personal_exportar_personal_estudio"
                   ]
               }
           },
           {
               path: "/reporte_administrativo",
               name: "reporte_administrativo",
               component: () => import("@/views/ReporteAdministrativo"),

               meta: {
                   title: "Reporte Administrativo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "reporte_administrativo_exportar",
                       "reporte_administrativo_exportar_personal",
                       "reporte_administrativo_exportar_personal_asistencia",
                       "reporte_administrativo_exportar_personal_laboral",
                       "reporte_administrativo_exportar_personal_onomastico",
                       "reporte_administrativo_exportar_personal_pago",
                       "reporte_administrativo_exportar_personal_justificacion",
                       "reporte_administrativo_exportar_personal_proyecto",
                       "reporte_administrativo_exportar_personal_estudio"
                   ]
               }
           },
           {
               path: "/notificacion",
               name: "notificacion",
               component: () => import("@/views/Notificacion"),

               meta: {
                   title: "Notificacion",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "notificacion_listar",
                       "notificacion_nuevo",
                       "notificacion_editar",
                       "notificacion_borrar",
                       "notificacion_ver",
                       "notificacion_busqueda",
                       "notificacion_exportar"
                   ]
               }
           },
           {
               path: "/tareo_personal",
               name: "tareo_personal",
               component: () => import("@/views/TareoPersonal"),

               meta: {
                   title: "Editar Tareo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "tareo_personal_listar",
                       "tareo_personal_editar",
                       "tareo_personal_nuevo_personal",
                       "tareo_personal_editar_personal",
                       "tareo_personal_matricular_personal",
                       "tareo_personal_quitar_personal",
                       "tareo_personal_enviar",
                       "tareo_personal_busqueda",
                       "tareo_personal_busqueda_supervisor",
                       "tareo_personal_importar",
                       "tareo_personal_exportar"
                   ]
               }
           },
           {
               path: "/tareo_personal2",
               name: "tareo_personal2",
               component: () => import("@/views/TareoPersonal2"),

               meta: {
                   title: "Verificar Tareo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "verificacion_tareo_listar",
                       "verificacion_tareo_ver",
                       "verificacion_tareo_ver_persona",
                       "verificacion_tareo_busqueda",
                       "verificacion_tareo_exportar_consulta_masiva",
                       "verificacion_tareo_importar_consulta_masiva",
                       "verificacion_tareo_aprobar",
                       "verificacion_tareo_rechazar",
                       "verificacion_tareo_exportar_excel",
                       "verificacion_tareo_exportar_pdf"
                   ]
               }
           },
           {
               path: "/base_general",
               name: "base_general",
               component: () => import("@/views/BaseGeneral"),

               meta: {
                   title: "Base General",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "base_general_listar",
                       "base_general_editar",
                       "base_general_borrar",
                       "base_general_busqueda",
                       // 'base_general_ver',
                       // 'base_general_ver_resumen',
                       // 'base_general_exportar',

                       "asignar_supervisor_nuevo",
                       "asignar_coordinador_nuevo",
                       "codigo_contingencia_listar"
                   ]
               }
           },
           {
               path: "/tareo_vendedor",
               name: "tareo_vendedor",
               component: () => import("@/views/TareoVendedor"),

               meta: {
                   title: "Tareo Vendedor",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "tareo_vendedor_listar",
                       "tareo_vendedor_editar",
                       "tareo_vendedor_ver",
                       "tareo_vendedor_importar",
                       "tareo_vendedor_busqueda",
                       "tareo_vendedor_exportar"
                   ]
               }
           },

           /*
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
    name: '404',
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
    name: '401',
  },

  {
    path: '/perfil',
    component: () => import('@/views/profile/index'),
    name: 'perfil',
    meta: { title: 'Perfil de Usuario', icon: 'user' }
  },

  {
    path: '/persona',
    name: 'persona',
    component: () => import('@/views/Persona')

    , meta: {
      title: 'Persona',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'persona_listar',
        'persona_nuevo',
        'persona_editar',
        'persona_borrar',
        'persona_ver',
        'persona_busqueda',
        'persona_exportar',
      ]
    }
  },
  {
    path: '/modalidad',
    name: 'modalidad',
    component: () => import('@/views/Modalidad')

    , meta: {
      title: 'Modalidades',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'modalidad_listar',
        'modalidad_nuevo',
        'modalidad_editar',
        'modalidad_borrar',
        'modalidad_ver',
        'modalidad_busqueda',
        'modalidad_exportar',
      ]
    }
  },
  {
    path: '/documento',
    name: 'documento',
    component: () => import('@/views/Documento')

    , meta: {
      title: 'Documento',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'documento_listar',
        'documento_nuevo',
        'documento_editar',
        'documento_borrar',
        'documento_ver',
        'documento_busqueda',
      ]
    }
  },
  {
    path: '/regimen',
    name: 'regimen',
    component: () => import('@/views/Regimen')

    , meta: {
      title: 'Régimen',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'regimen_listar',
        'regimen_nuevo',
        'regimen_editar',
        'regimen_borrar',
        'regimen_ver',
        'regimen_busqueda',
        'regimen_exportar',
      ]
    }
  },
  {
    path: '/escala',
    name: 'escala',
    component: () => import('@/views/Escala')

    , meta: {
      title: 'Escala',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'escala_listar',
        'escala_nuevo',
        'escala_editar',
        'escala_borrar',
        'escala_ver',
        'escala_busqueda',
      ]
    }
  },
  {
    path: '/sede',
    name: 'sede',
    component: () => import('@/views/Sede')

    , meta: {
      title: 'Sedes',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'sede_listar',
        'sede_nuevo',
        'sede_editar',
        'sede_borrar',
        'sede_ver',
        'sede_busqueda',
        'sede_exportar',
      ]
    }
  },
  {
    path: '/afp',
    name: 'afp',
    component: () => import('@/views/Afp')

    , meta: {
      title: 'AFP',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'afp_listar',
        'afp_nuevo',
        'afp_editar',
        'afp_borrar',
        'afp_ver',
        'afp_busqueda',
        'afp_exportar',
      ]
    }
  },
  {
    path: '/banco',
    name: 'banco',
    component: () => import('@/views/Banco')

    , meta: {
      title: 'Bancos',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'banco_listar',
        'banco_nuevo',
        'banco_editar',
        'banco_borrar',
        'banco_ver',
        'banco_busqueda',
        'banco_exportar',
      ]
    }
  },
  {
    path: '/campaña',
    name: 'campagna',
    component: () => import('@/views/Campagna')

    , meta: {
      title: 'Campañas',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'campagna_listar',
        'campagna_nuevo',
        'campagna_editar',
        'campagna_borrar',
        'campagna_ver',
        'campagna_busqueda',
        'campagna_exportar',
      ]
    }
  },
  {
    path: '/puesto',
    name: 'puesto',
    component: () => import('@/views/Puesto')

    , meta: {
      title: 'Puestos',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'puesto_listar',
        'puesto_nuevo',
        'puesto_editar',
        'puesto_borrar',
        'puesto_ver',
        'puesto_busqueda',
        'puesto_exportar',
      ]
    }
  },
  {
    path: '/rango',
    name: 'rango',
    component: () => import('@/views/Rango')

    , meta: {
      title: 'Rangos',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'rango_listar',
        'rango_nuevo',
        'rango_editar',
        'rango_borrar',
        'rango_ver',
        'rango_busqueda',
        'rango_exportar',
      ]
    }
  },
  {
    path: '/reclutamiento',
    name: 'reclutamiento',
    component: () => import('@/views/Reclutamiento')

    , meta: {
      title: 'Reclutamientos',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'reclutamiento_listar',
        'reclutamiento_nuevo',
        'reclutamiento_editar',
        'reclutamiento_borrar',
        'reclutamiento_ver',
        'reclutamiento_busqueda',
        'reclutamiento_exportar',
      ]
    }
  },






  {
    path: '/t_registro',
    name: 't_registro',
    component: () => import('@/views/TRegistro')
    , meta: {
      title: 'T-Registro',
      icon: 'tie',
      roles: ['admin'],
      permissions: [
        't_registro_listar',
        't_registro_nuevo',
        't_registro_editar',
        't_registro_ver',
        't_registro_periodo',
        't_registro_estudio_capacitacion',
        't_registro_experiencia_laboral',
        't_registro_cargo_confianza',
        't_registro_merito',
        't_registro_busqueda',
        't_registro_exportar',
      ]
    }
  },
  {
    path: '/administrativos',
    name: 'administrativos',
    component: () => import('@/views/Administrativo')
    , meta: {
      title: 'Administrativos',
      icon: 'tie',
      roles: ['admin'],
      permissions: [
        'administrativo_listar',
        'administrativo_agregar',
        'administrativo_activar',
        'administrativo_desactivar',
        'administrativo_crear_justificacion',
        'administrativo_editar',
        'administrativo_ver',
        'administrativo_ver_asistencia',
        'administrativo_ver_planilla',
        'administrativo_regimen_persona',
        'administrativo_modalidad_persona',
        'administrativo_cargo_persona',
        'administrativo_escala_persona',
        'administrativo_discapacidad_persona',

        'administrativo_evaluacion',
        'administrativo_experiencia_laboral',
        'administrativo_capacitacion',
        'administrativo_discapacidad',
        'administrativo_habilidad',
        'administrativo_aptitud',
        'administrativo_estudio',
        'administrativo_idioma',
        'administrativo_merito',
        'administrativo_demerito',
        'administrativo_sancion',
        'administrativo_regimen_aseguramiento',
        'administrativo_regimen_pensionario',
        'administrativo_sctr_salud',
        'administrativo_certificado_habilidad',
        'administrativo_bonificacion_familiar',


        'administrativo_borrar',
        'administrativo_busqueda',
        'administrativo_exportar',
      ]
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/Personal')

    , meta: {
      title: 'Personal',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'personal_listar',
        'personal_nuevo',
        'personal_editar',
        'personal_borrar',
        'personal_ver',
        'personal_busqueda',
        'personal_listar_historico',
        'personal_contrato',
        'personal_restaurar',
        'personal_supervisor',
        'personal_discapacidad_persona',
        'personal_exportar',
      ]
    }
  },
  {
    path: '/persona_cuenta',
    name: 'persona_cuenta',
    component: () => import('@/views/PersonaCuenta')

    , meta: {
      title: 'Cuenta',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'persona_cuenta_listar',
        'persona_cuenta_nuevo',
        'persona_cuenta_editar',
        'persona_cuenta_borrar',
        'persona_cuenta_ver',
        'persona_cuenta_busqueda',
        'persona_cuenta_exportar',
      ]
    }
  },
  {
    path: '/pago',
    name: 'pago',
    component: () => import('@/views/ComisionPagoFinal')

    , meta: {
      title: 'Pago',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'comision_pago_final_listar',
        'comision_pago_final_nuevo',
        'comision_pago_final_editar',
        'comision_pago_final_borrar',
        'comision_pago_final_ver',
        'comision_pago_final_busqueda',
        'comision_pago_final_exportar',
      ]
    }
  },
  {
    path: '/secuencia_persona',
    name: 'secuencia_persona',
    component: () => import('@/views/SecuenciaPersona')

    , meta: {
      title: 'Secuencia',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'secuencia_persona_listar',
        'secuencia_persona_nuevo',
        'secuencia_persona_editar',
        'secuencia_persona_borrar',
        'secuencia_persona_ver',
        'secuencia_persona_busqueda',
        'secuencia_persona_exportar',
      ]
    }
  },
  {
    path: '/area',
    name: 'area',
    component: () => import('@/views/Area')

    , meta: {
      title: 'Areas',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'area_listar',
        'area_nuevo',
        'area_editar',
        'area_borrar',
        'area_ver',
        'area_busqueda',
        'area_exportar',
      ]
    }
  },
  {
    path: '/cargo',
    name: 'cargo',
    component: () => import('@/views/Cargo')

    , meta: {
      title: 'Cargo',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'cargo_listar',
        'cargo_nuevo',
        'cargo_editar',
        'cargo_borrar',
        'cargo_ver',
        'cargo_busqueda',
        'cargo_exportar',
      ]
    },
  },
  {
    path: '/cargo_clasificado',
    name: 'cargo_clasificado',
    component: () => import('@/views/CargoClasificado')

    , meta: {
      title: 'Cargo Clasificado',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'cargo_clasificado_listar',
        'cargo_clasificado_nuevo',
        'cargo_clasificado_editar',
        'cargo_clasificado_borrar',
        'cargo_clasificado_ver',
        'cargo_clasificado_busqueda',
        'cargo_clasificado_exportar',
      ]
    }, 
    }, 
    {
    path: '/obra',
    name: 'obra',
    component: () => import('@/views/Obra')

    , meta: {
      title: 'Asignacion Permiso',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'obra_listar',
        'obra_nuevo',
        'obra_editar',
        'obra_borrar',
        'obra_ver',
        'obra_busqueda',
        'obra_exportar',
      ]
    }
  },
*/
           {
               path: "/permiso",
               name: "permiso",
               component: () => import("@/views/Permiso"),

               meta: {
                   title: "Permisos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "permiso_listar",
                       "permiso_nuevo",
                       "permiso_editar",
                       "permiso_borrar",
                       "permiso_ver",
                       "permiso_busqueda"
                   ]
               }
           },
           {
               path: "/role",
               name: "role",
               component: () => import("@/views/Role"),

               meta: {
                   title: "Roles",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "role_listar",
                       "role_nuevo",
                       "role_editar",
                       "role_borrar",
                       "role_ver",
                       "role_busqueda"
                   ]
               }
           },
           {
               path: "/configuracion_inicial",
               name: "configuracion_inicial",
               component: () => import("@/views/Configuracion"),

               meta: {
                   title: "Config. Inicial",
                   icon: "lock",
                   roles: ["admin"],
                   // permissions: [],
                   permissions: [
                       "configuracion_inicial_listar",
                       "configuracion_inicial_nuevo",
                       "configuracion_inicial_editar",
                       "configuracion_inicial_borrar",
                       "configuracion_inicial_ver",
                       "configuracion_inicial_busqueda"
                   ]
               }
           },
           {
               path: "/usuario",
               name: "usuario",
               component: () => import("@/views/Usuario"),

               meta: {
                   title: "Usuarios",
                   icon: "lock",
                   roles: ["admin"],
                   // permissions: [],
                   permissions: [
                       "usuario_listar",
                       "usuario_nuevo",
                       "usuario_editar",
                       "usuario_borrar",
                       "usuario_ver",
                       "usuario_busqueda"
                   ]
               }
           },
           {
               path: "/desarrollo",
               name: "desarrollo",
               component: () => import("@/views/EnDesarrollo"),

               meta: {
                   title: "Desarrollo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
           },

           /**
            * RUTAS FALTANTES POR IMPLEMENTAR
            */
           {
               path: "/empresa",
               name: "empresa",
               component: () => import("@/views/Empresa"),

               meta: {
                   title: "Empresas",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "empresa_listar",
                       "empresa_nuevo",
                       "empresa_editar",
                       "empresa_borrar",
                       "empresa_ver",
                       "empresa_busqueda",
                       "empresa_exportar"
                   ]
               }
           },
           /* {
    path: '/empleado',
    name: 'empleado',
    component: () => import('@/views/Vendedor')

    , meta: {
      title: 'Empleados',
      icon: 'lock',
      roles: ['admin'],
      permissions: [
        'empleado_listar',
        'empleado_nuevo',
        'empleado_editar',
        'empleado_borrar',
        'empleado_ver',
        'empleado_busqueda',
        'empleado_exportar',
      ]
    }
  }, */
           {
               path: "/local",
               name: "local",
               component: () => import("@/views/Sede"),

               meta: {
                   title: "Locales",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
           },
           {
               path: "/sub_categoria",
               name: "sub_categoria",
               component: () => import("@/views/SubCategoria"),

               meta: {
                   title: "Subcategorías",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "sub_categoria_listar",
                       "sub_categoria_nuevo",
                       "sub_categoria_editar",
                       "sub_categoria_borrar",
                       "sub_categoria_ver",
                       "sub_categoria_busqueda"
                   ]
               }
           },
           {
               path: "/desarrollo",
               name: "desarrollo",
               component: () => import("@/views/EnDesarrollo"),

               meta: {
                   title: "Desarrollo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
           },
           {
               path: "/cliente",
               name: "cliente",
               component: () => import("@/views/Cliente"),

               meta: {
                   title: "Clientes",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "cliente_listar",
                       "cliente_nuevo",
                       "cliente_editar",
                       "cliente_borrar",
                       "cliente_ver",
                       "cliente_busqueda"
                   ]
               }
           },
           {
               path: "/codigo_contingencia",
               name: "codigo_contingencia",
               component: () => import("@/views/CodigoContingencia"),

               meta: {
                   title: "Codigos contingencia",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "codigo_contingencia_listar",
                       "codigo_contingencia_asignar",
                       "codigo_contingencia_desasignar",
                       "codigo_contingencia_exportar"
                   ]
               }
           },
           {
               path: "/asignacion_supervisor",
               name: "asignacion_supervisor",
               component: () => import("@/views/AsignacionSupervisor"),

               meta: {
                   title: "Asignación supervisor",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: ["asignar_supervisor_nuevo"]
               }
           },
           {
               path: "/asignacion_coordinador",
               name: "asignacion_coordinador",
               component: () => import("@/views/AsignacionCoordinador"),

               meta: {
                   title: "Asignación coordinador",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: ["asignar_coordinador_nuevo"]
               }
           },
           {
               path: "/asistencia",
               name: "asistencia",
               component: () => import("@/views/Asistencia"),

               meta: {
                   title: "Asistencia",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "asistencia_listar",
                       // 'asistencia_nuevo',
                       // 'asistencia_editar',
                       // 'asistencia_ver',
                       // 'asistencia_borrar',
                       "asistencia_busqueda",
                       "asistencia_exportar"
                   ]
               }
           },
           /*
           {
               path: "/tareo",
               name: "tareo",
               component: () => import("@/views/Tareo"),

               meta: {
                   title: "Tareo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "tareo_listar",
                       "tareo_editar",
                       "tareo_ver",
                       "tareo_importar",
                       "tareo_busqueda",
                       "tareo_exportar"
                   ]
               }
           },
           */
           {
               path: "/turnos",
               name: "turnos",
               component: () => import("@/views/Turnos"),

               meta: {
                   title: "Turnos",
                   // icon: 'clock',
                   roles: ["admin"],
                   permissions: [
                       "turno_listar",
                       "turno_nuevo",
                       "turno_editar",
                       "turno_borrar",
                       "turno_turno",
                       "turno_ver",
                       "turno_busqueda",
                       "turno_exportar"
                   ]
               }
           },
           {
               path: "/horarios",
               name: "horarios",
               component: () => import("@/views/Horarios"),

               meta: {
                   title: "Horarios",
                   // icon: 'clock',
                   roles: ["admin"],
                   permissions: [
                       "horario_listar",
                       "horario_nuevo",
                       "horario_editar",
                       "horario_borrar",
                       "horario_turno",
                       "horario_ver",
                       "horario_busqueda",
                       "horario_exportar"
                   ]
               }
           },
           {
               path: "/biometricos",
               name: "biometricos",
               component: () => import("@/views/Biometricos"),

               meta: {
                   title: "Biometricos",
                   // icon: 'clock',
                   roles: ["admin"],
                   permissions: [
                       "biometrico_listar",
                       "biometrico_nuevo",
                       "biometrico_editar",
                       "biometrico_ver",
                       "biometrico_borrar",
                       "biometrico_persona",
                       "biometrico_obtener_registros",
                       "biometrico_obtener_registros_usb",
                       "biometrico_guadar_bd",
                       "biometrico_guardar_reloj",
                       "biometrico_quitar_reloj",
                       "biometrico_busqueda",
                       "biometrico_exportar"
                   ]
               }
           },
           {
               path: "/biometricos/tareas",
               name: "biometricos_tarea",
               component: () => import("@/views/BiometricosTareas"),

               meta: {
                   title: "Tareas Biometrico",
                   // icon: 'clock',
                   roles: ["admin"],
                   permissions: []
               }
           },
           {
               path: "/atributos",
               name: "atributos",
               component: () => import("@/views/Atributos"),

               meta: {
                   title: "Atributos",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "atributo_listar",
                       "atributo_nuevo",
                       "atributo_editar",
                       "atributo_borrar",
                       "atributo_ver",
                       "atributo_busqueda",
                       "atributo_exportar"
                   ]
               }
           },
           {
               path: "/motivo_rechazo",
               name: "motivo_rechazo",
               component: () => import("@/views/MotivosRechazos"),

               meta: {
                   title: "Motivo Rechazo",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
    },
           
           {
               path: "/planilla_personal",
               name: "planilla_personal",
               component: () => import("@/views/Planilla"),

               meta: {
                   title: "Planillas",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "planilla_listar",
                       "planilla_editar",
                       "planilla_ver_asistencia",
                       "planilla_ver_boleta",
                       "planilla_cerrar_planilla",
                       "planilla_busqueda",
                       "planilla_exportar"
                   ]
               }
           },
           {
               path: "/tipo_planilla",
               name: "tipo_planilla",
               component: () => import("@/views/TipoPlanilla"),

               meta: {
                   title: "Tipos de Planilla",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "tipo_planilla_listar",
                       "tipo_planilla_nuevo",
                       "tipo_planilla_editar",
                       "tipo_planilla_borrar",
                       "tipo_planilla_ver",
                       "tipo_planilla_busqueda",
                       "tipo_planilla_exportar"
                   ]
               }
           },
           {
               path: "/configuracion_planilla",
               name: "configuracion_planilla",
               component: () => import("@/views/ConfiguracionPlanilla"),

               meta: {
                   title: "Configuración de Planilla",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       
                       "tipo_planilla_listar",
                       "tipo_planilla_nuevo",
                       "tipo_planilla_editar",
                       "tipo_planilla_borrar",
                       "tipo_planilla_ver",
                       "tipo_planilla_busqueda",
                       "tipo_planilla_exportar"
                   ]
               }
           },
          
           {
               path: "/persona_horario",
               name: "persona_horario",
               component: () => import("@/views/PersonaHorario"),

               meta: {
                   title: "Persona-Horario",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "persona_horario_listar",
                       "persona_horario_nuevo",
                       "persona_horario_editar",
                       "persona_horario_borrar",
                       "persona_horario_ver",
                       "persona_horario_busqueda",
                       "persona_horario_exportar"
                   ]
               }
           },
           {
               path: "/persona_turno",
               name: "persona_turno",
               component: () => import("@/views/PersonaTurno"),

               meta: {
                   title: "Persona-Turno",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "persona_turno_listar",
                       "persona_turno_nuevo",
                       "persona_turno_editar",
                       "persona_turno_borrar",
                       "persona_turno_turno",
                       "persona_turno_ver",
                       "persona_turno_busqueda",
                       "persona_turno_exportar"
                   ]
               }
           },
           {
               path: "/planilla_personal2",
               name: "planilla_personal2",
               component: () => import("@/views/PlanillaPersonal"),

               meta: {
                   title: "Planilla",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "planilla_personal_listar",
                       "planilla_personal_nuevo",
                       "planilla_personal_editar",
                       "planilla_personal_borrar",
                       "planilla_personal_ver",
                       "planilla_personal_busqueda",
                       "planilla_personal_exportar"
                   ]
               }
    },
           {
               path: "/persona_planilla",
               name: "persona_planilla",
               component: () => import("@/views/PersonaPlanilla"),
               meta: {
                   title: "Personas en Planilla",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "persona_planilla_listar",
                       "persona_planilla_nuevo",
                       "persona_planilla_editar",
                       "persona_planilla_borrar",
                       "persona_planilla_ver",
                       "persona_planilla_busqueda",
                       "persona_planilla_exportar"
                   ]
               }
    },
           {
               path: "/vacacion_administrativo",
               name: "vacacion_administrativo",
               component: () => import("@/views/VacacionAdministrativo"),
               meta: {
                   title: "Vacaciones",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "vacacion_administrativo_listar",
                       "vacacion_administrativo_nuevo",
                       "vacacion_administrativo_editar",
                       "vacacion_administrativo_ver",
                       "vacacion_administrativo_borrar",
                       "vacacion_administrativo_busqueda",
                       "vacacion_administrativo_exportar",
                       "vacacion_administrativo_exportar_por_trabajador"
                   ]
               }
           },
           {
               path: "/vacacion",
               name: "vacacion",
               component: () => import("@/views/Vacacion"),

               meta: {
                   title: "Vacaciones",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "vacacion_listar",
                       "vacacion_nuevo",
                       "vacacion_editar",
                       "vacacion_ver",
                       "vacacion_borrar",
                       "vacacion_busqueda",
                       "vacacion_exportar"
                   ]
               }
           },
           {
               path: "/habilidad",
               name: "habilidad",
               component: () => import("@/views/Habilidad"),

               meta: {
                   title: "Habilidad",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "habilidad_listar",
                       "habilidad_nuevo",
                       "habilidad_editar",
                       "habilidad_ver",
                       "habilidad_borrar",
                       "habilidad_busqueda",
                       "habilidad_exportar"
                   ]
               }
    },
            /*
           {
               path: "/cargo_area",
               name: "cargo_area",
               component: () => import("@/views/CargoArea"),

               meta: {
                   title: "Cargo Area",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "cargo_area_listar",
                       "cargo_area_nuevo",
                       "cargo_area_editar",
                       "cargo_area_ver",
                       "cargo_area_borrar",
                       "cargo_area_busqueda",
                       "cargo_area_exportar"
                   ]
               }
           },
           */
           {
               path: "/cuenta",
               name: "cuenta",
               component: () => import("@/views/Cuenta"),

               meta: {
                   title: "Cuenta",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "cuenta_listar",
                       "cuenta_nuevo",
                       "cuenta_editar",
                       "cuenta_ver",
                       "cuenta_borrar",
                       "cuenta_busqueda",
                       "cuenta_exportar"
                   ]
               }
           },
           {
               path: "/secuencia",
               name: "secuencia",
               component: () => import("@/views/Secuencia"),

               meta: {
                   title: "Secuencia",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "secuencia_listar",
                       "secuencia_nuevo",
                       "secuencia_editar",
                       "secuencia_ver",
                       "secuencia_residente",
                       "secuencia_supervisor",
                       "secuencia_historico",
                       "secuencia_borrar",
                       "secuencia_busqueda",
                       "secuencia_exportar"
                   ]
               }
           },
           {
                path: "/meta",
                name: "meta",
                component: () => import("@/views/Meta"),

                meta: {
                    title: "Meta",
                    icon: "lock",
                    roles: ["admin"],
                    permissions: [
                        "meta_listar",
                        "meta_nuevo",
                        "meta_editar",
                        "meta_ver",
                        "meta_borrar",
                        "meta_busqueda",
                        "meta_exportar"
                    ]
                }
            },
           {
               path: "/caja",
               name: "caja",
               component: () => import("@/views/EnDesarrollo"),

               meta: {
                   title: "Caja",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
           },
           {
               path: "/solicitud",
               name: "solicitud",
               component: () => import("@/views/EnDesarrollo"),

               meta: {
                   title: "Solicitudes",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: []
               }
           },
           {
               path: "/icons",
               name: "icons",
               //component: () => import('@/views/Icons')
               component: () => import("@/views/demo/Icons"),
               meta: {
                   title: "Demo de Iconos",
                   icon: "lock"
               }
           },
           {
               path: "/concepto_planilla",
               name: "concepto_planilla",
               component: () => import("@/views/ConceptosPlanillas.vue"),
               meta: {
                   title: "Conceptos planillas",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "concepto_planilla_listar",
                       "concepto_planilla_nuevo",
                       "concepto_planilla_editar",
                       "concepto_planilla_borrar",
                       "concepto_planilla_ver",
                       "concepto_planilla_busqueda",
                       "concepto_planilla_exportar"
                   ]
               }
           },
           {
               path: "/justificacion_p",
               name: "justificacion_p",
               component: () => import("@/views/JustificacionPersonal.vue"),
               meta: {
                   title: "Justificaciones",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "justificacion_personal_listar",
                       "justificacion_personal_nuevo",
                       "justificacion_personal_editar",
                       "justificacion_personal_borrar",
                       "justificacion_personal_ver",
                       "justificacion_personal_busqueda",
                       "justificacion_personal_exportar"
                   ]
               }
           },

           {
               path: "/justificacion_a",
               name: "justificacion_a",
               component: () =>
                   import("@/views/JustificacionAdministrativo.vue"),
               meta: {
                   title: "Justificaciones",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "justificacion_administrativo_listar",
                       "justificacion_administrativo_nuevo",
                       "justificacion_administrativo_editar",
                       "justificacion_administrativo_borrar",
                       "justificacion_administrativo_ver",
                       "justificacion_administrativo_busqueda",
                       "justificacion_administrativo_exportar"
                   ]
               }
           },
           {
               path: "/justificaciones_u",
               name: "justificaciones_u",
               component: () => import("@/views/JustificacionesUsuario.vue"),
               meta: {
                   title: "Solicitudes de Papeletas",
                   icon: "user",
                   roles: ["admin"],
                   permissions: [
                       "justificacion_personal_aprobar",
                       //                       "justificacion_personal_listar",
                       //                       "justificacion_personal_solicitudes",
                       "justificacion_personal_nuevo",
                       "justificacion_personal_editar",
                       "justificacion_personal_borrar",
                       "justificacion_personal_ver",
                       "justificacion_personal_busqueda",
                       "justificacion_personal_exportar"
                   ]
               }
           },

           {
               path: "/tipo_justificacion",
               name: "tipo_justificacion",
               component: () => import("@/views/TipoJustificacion.vue"),
               meta: {
                   title: "Tipo Justificacion",
                   icon: "lock",
                   roles: ["admin"],
                   permissions: [
                       "tipo_justificacion_listar",
                       "tipo_justificacion_nuevo",
                       "tipo_justificacion_editar",
                       "tipo_justificacion_ver",
                       "tipo_justificacion_borrar",
                       "tipo_justificacion_busqueda",
                       "tipo_justificacion_exportar"
                   ]
               }
           },

           {
               path: "/CamposPlanilla",
               name: "CamposPlanilla",
               component: () => import("@/views/CamposesPlanillas.vue"),
               meta: {
                   title: "Camposes planillas",
                   icon: "lock"
               }
           },

           {
               path: "/CamposPlanilla",
               name: "CamposPlanilla",
               component: () => import("@/views/CamposesPlanillas.vue"),
               meta: {
                   title: "Camposes planillas",
                   icon: "lock"
               }
           },

           {
               path: "/Horario",
               name: "Horario",
               component: () => import("@/views/Horarios.vue"),
               meta: {
                   title: "Horarios",
                   icon: "lock"
               }
           },

           ,
           /* @{new_api_route}             */
           {
               path: "*",
               component: () => import("@/views/error-page/404")
           }
           // { path: '*', redirect: '/404' }
       ];

const createRouter = () =>
    new Router({
        mode: "history", // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes
    });

const router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
