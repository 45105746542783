"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ifvisible_ts_1 = require("@/utils/ifvisible.ts");
var Timer = /** @class */ (function () {
    function Timer(ifvisible, seconds, callback) {
        var _this = this;
        this.ifvisible = ifvisible;
        this.seconds = seconds;
        this.callback = callback;
        this.stopped = false;
        this.start();
        this.ifvisible.on('statusChanged', function (data) {
            if (_this.stopped === false) {
                if (data.status === ifvisible_ts_1.STATUS_ACTIVE) {
                    _this.start();
                }
                else {
                    _this.pause();
                }
            }
        });
    }
    Timer.prototype.start = function () {
        this.stopped = false;
        clearInterval(this.token);
        this.token = setInterval(this.callback, this.seconds * 1000);
    };
    Timer.prototype.stop = function () {
        this.stopped = true;
        clearInterval(this.token);
    };
    Timer.prototype.resume = function () {
        this.start();
    };
    Timer.prototype.pause = function () {
        this.stop();
    };
    return Timer;
}());
exports.default = Timer;
