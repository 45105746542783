<template>
  <!--
  <date-picker-2
    :append-to-body="false"
    v-model="date"
    :type="typeDate"
    lang="es"
    :format="format"
    :inputClass="'form-control'"
    :placeholder="format"
    @change="handleChange"
    :disabled="disabled"
    :editable="true"
    :clearable="true"
  ></date-picker-2>
  -->
  <div :class="['date-picker',error?'error':'',mobile?'mobile':'']">
    <input
      type="date"
      :class="['form-control',required?'required':'']"
      :append-to-body="false"
      :value="tmpValue"
      @input="tmpValue = $event.target.valueAsDate"
      :type="typeDate"
      lang="es"
      :format="format"
      :inputClass="'form-control'"
      :placeholder="format"
      @change="handleChange"
      :disabled="disabled"
      :editable="true"
      :clearable="true"
      :max="max_date"
      :min="min_date"
    />
    <!--
    <input v-model="showValue" :placeholder="format" :disabled="disabled" @blur="updateValue" />
    
    -->
  </div>
</template>
<script>
/*
const DatePicker = () => import("vue2-datepicker");
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";
*/

 import moment from "moment"; 
export default {
  //  components: { "date-picker-2": DatePicker },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    format: { type: String | Function, default: "DD-MM-YYYY" },
    value: {
      type: String | Date
    },
    typeDate: {
      type: String,
      default: "date"
    },
    required: {
      type: String|Boolean,
      default: false
    },
    max_date: {
      type: String | Date
    },
    min_date: {
      type: String | Date
    }
  },
  data() {
    return {
      localValue: this.value,
      localShowValue: null,
      error: false,
      mobile: false
    };
  },
  created() {
    if (mobilecheck()) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  },
  methods: {
    handleChange() {
      //  console.log("hc", this.localValue);

      if (this.localValue)
        if (typeof this.localValue == "string")
          this.$emit("input", this.localValue);
        else
          this.$emit(
            "input",
            this.localValue
              .toISOString()
              .replace(" ", "T")
              .split("T")[0]
          );
      else {
        this.localValue = null;
        this.$emit("input", null);
      }
    },
    updateValue() {
      if (this.localShowValue) {
        let vdate = moment(this.localShowValue, this.format);
        if (vdate && vdate.isValid()) {
          this.tmpValue = vdate;
          this.error = false;
          this.localShowValue = null;
        } else {
          this.localShowValue = vdate.format(this.format);
        }
      } else {
        this.error = false;
        this.localShowValue = null;
      }
    }
  },
  watch: {
    value(v, ov) {
      this.tmpValue = v;
    }
  },
  computed: {
    showValue: {
      get() {
        if (!this.localShowValue) {
          if (this.localValue)
            return moment(this.localValue).format(this.format);
          else return null;
        } else return this.localShowValue;
      },
      set(v) {
        this.localShowValue = v;
        if (v) {
          let vdate = moment(v, this.format);
          //  console.log("set1", v, vdate);
          if (vdate && vdate.isValid()) {
            this.error = false;
          } else {
            this.error = true;
          }
        }
      }
    },
    tmpValue: {
      get() {
        if (!this.localValue) {
          return null;
        }

        let vdate = moment(this.localValue);
        if (vdate && vdate.isValid()) {
          return vdate
            .toISOString()
            .replace(" ", "T")
            .split("T")[0];
        } else {
          return this.localValue;
        }
      },
      set(v) {
        if (!v) {
          this.error = false;
          this.localValue = null;
          this.$emit("input");
          return;
        }
        let vdate = moment(v);

        if (vdate && vdate.isValid()) {
          this.error = false;

          this.$emit(
            "input",
            vdate
              .toISOString()
              .replace(" ", "T")
              .split("T")[0]
          );
        } else {
          this.error = true;
        }
        this.localValue = v;
      }
    }
  }
};
</script>
<style>
.date-picker{
  max-height: calc(1.2em + 0.36rem + 2px);
margin-bottom: 2px;
}
.date-picker input {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}

.mx-input:disabled,
.mx-input.disabled {
  color: #555;
  /*
    background-color: #f3f3f3;
    border-color: #ccc;
    cursor: not-allowed;
    */
  cursor: auto;
}
</style>
<style scoped>
.error *,
.error {
  color: red;
  background-color: rgba(255, 100, 100, 0.05);
}
</style>