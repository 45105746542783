import mqtt from "mqtt";
var client;

export default {
    install(Vue, options) {
        let config = {
            //clean: true, // Reserved session
            connectTimeout: 4000, // Time out
            reconnectPeriod: 4000, // Reconnection interval
            // Certification Information
            clientId: window.uniqid("mqttjs_"),
            // username: 'public',
            // password: 'public',
            username: "bioclient",
            password: "biopasswd",
            //secureProtocol: 'TLS_method',
            host: "mqtt.jnissiac.com",
            port: 443
            // endpoint: '/ws',
            // endpoint: '/mqtt',
            //path: '/mqtt',
        };
        //const nombre='muni_sansalvador_2022'
        //const nombre='muni_vilcabamba_2022'
        //const nombre=process.env.mqtt_channel
        //const nombre = 'muni_megantoni_2022'
        const nombre = window.base.channel

        if (nombre) {
            client = mqtt.connect("wss://" + config.host, config);
            client.on("connect", () => {
                window.xxx = this;
                window.yyy = Vue;
                console.log("Connection succeeded to "+nombre, this);

                //            client.subscribe("biometric/messages/muni1/#", {});
                client.subscribe("biometric/"+nombre+"/#", {});
                //client.subscribe("biometric/muni_vilcabamba_2022/#", {});
    //            biometric/muni_vilcabamba_2022/main
            });
            client.on("error", error => {
                console.log("Connection failed", error);
            });
            client.on("message", (topic, message) => {
                //this.receiveNews = this.receiveNews.concat(message)
                //          console.log(`Received message ${message} from topic ${topic}`);
               // console.log(`Received message from topic ${topic}`);
                window.bus.emit("bio_message", message);
                //  client.end()
            });

            Vue.prototype.$mqtt = {
                config: config,
                client: null,
                init() {
                    this.client = mqtt.connect(
                        "wss://mqtt.jnissiac.com:443",
                        config
                    );
                }
            };
        }
            else {
                console.error('channel not defined')
}
    }
};
/*

 client=mqtt.connect("wss://mqtt.jnissiac.com:443",{
 
  
  //clean: true, // Reserved session
  connectTimeout: 4000, // Time out
  reconnectPeriod: 4000, // Reconnection interval
  // Certification Information
  clientId: 'mqttjs_311be2c321',
  // username: 'public',
  // password: 'public',
  username: 'admin',
   password: 'adminadmin', 
   //secureProtocol: 'TLS_method',
  host: 'mqtt.jnissiac.com',
        port: 443,
       // endpoint: '/ws',
        // endpoint: '/mqtt',
   //path: '/mqtt',
})
client.on('connect', () => {
  console.log('Connection succeeded!')

})
client.on('error', error => {
  console.log('Connection failed', error)
})
client.on('message', (topic, message) => {
  //this.receiveNews = this.receiveNews.concat(message)
  console.log(`Received message ${message} from topic ${topic}`)
//  client.end()
})
client.subscribe('test/#',{} )



*/
