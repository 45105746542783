"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IfVisible = exports.IE = exports.STATUS_HIDDEN = exports.STATUS_IDLE = exports.STATUS_ACTIVE = void 0;
var Events_ts_1 = require("@/utils/Events.ts");
var Timers_ts_1 = require("@/utils/Timers.ts");
exports.STATUS_ACTIVE = 'active';
exports.STATUS_IDLE = 'idle';
exports.STATUS_HIDDEN = 'hidden';
// declare var __VERSION__: string;
var DOC_HIDDEN;
var VISIBILITY_CHANGE_EVENT;
// eslint-disable-next-line func-names
exports.IE = (function () {
    var undef;
    var v = 3;
    var div = document.createElement('div');
    var all = div.getElementsByTagName('i');
    // eslint-disable-next-line no-cond-assign
    while (
    // eslint-disable-next-line no-plusplus, no-sequences
    div.innerHTML = "<!--[if gt IE " + ++v + "]><i></i><![endif]-->",
        all[0])
        ;
    return v > 4 ? v : undef;
}());
var IfVisible = /** @class */ (function () {
    function IfVisible(root, doc) {
        var _this = this;
        this.root = root;
        this.doc = doc;
        this.status = exports.STATUS_ACTIVE;
        this.VERSION = '2.0.11';
        this.timers = [];
        this.idleTime = 30000;
        this.isLegacyModeOn = false;
        // Find correct browser events
        if (this.doc.hidden !== undefined) {
            DOC_HIDDEN = 'hidden';
            VISIBILITY_CHANGE_EVENT = 'visibilitychange';
        }
        else if (this.doc.mozHidden !== undefined) {
            DOC_HIDDEN = 'mozHidden';
            VISIBILITY_CHANGE_EVENT = 'mozvisibilitychange';
        }
        else if (this.doc.msHidden !== undefined) {
            DOC_HIDDEN = 'msHidden';
            VISIBILITY_CHANGE_EVENT = 'msvisibilitychange';
        }
        else if (this.doc.webkitHidden !== undefined) {
            DOC_HIDDEN = 'webkitHidden';
            VISIBILITY_CHANGE_EVENT = 'webkitvisibilitychange';
        }
        if (DOC_HIDDEN === undefined) {
            this.legacyMode();
        }
        else {
            var trackChange = function () {
                if (_this.doc[DOC_HIDDEN]) {
                    _this.blur();
                }
                else {
                    _this.focus();
                }
            };
            trackChange(); // get initial status
            Events_ts_1.Events.dom(this.doc, VISIBILITY_CHANGE_EVENT, trackChange);
        }
        this.startIdleTimer();
        this.trackIdleStatus();
    }
    IfVisible.prototype.legacyMode = function () {
        var _this = this;
        // it's already on
        if (this.isLegacyModeOn) {
            return;
        }
        var BLUR_EVENT = 'blur';
        var FOCUS_EVENT = 'focus';
        if (exports.IE < 9) {
            BLUR_EVENT = 'focusout';
        }
        Events_ts_1.Events.dom(this.root, BLUR_EVENT, function () {
            return _this.blur();
        });
        Events_ts_1.Events.dom(this.root, FOCUS_EVENT, function () {
            return _this.focus();
        });
        this.isLegacyModeOn = true;
    };
    IfVisible.prototype.startIdleTimer = function (event) {
        var _this = this;
        // Prevents Phantom events.
        // @see https://github.com/serkanyersen/ifvisible.js/pull/37
        if (event instanceof MouseEvent && event.movementX === 0 && event.movementY === 0) {
            return;
        }
        this.timers.map(clearTimeout);
        this.timers.length = 0; // clear the array
        if (this.status === exports.STATUS_IDLE) {
            this.wakeup();
        }
        this.idleStartedTime = +(new Date());
        this.timers.push(setTimeout(function () {
            if (_this.status === exports.STATUS_ACTIVE || _this.status === exports.STATUS_HIDDEN) {
                return _this.idle();
            }
        }, this.idleTime));
    };
    IfVisible.prototype.trackIdleStatus = function () {
        Events_ts_1.Events.dom(this.doc, 'mousemove', this.startIdleTimer.bind(this));
        Events_ts_1.Events.dom(this.doc, 'mousedown', this.startIdleTimer.bind(this));
        Events_ts_1.Events.dom(this.doc, 'keyup', this.startIdleTimer.bind(this));
        Events_ts_1.Events.dom(this.doc, 'touchstart', this.startIdleTimer.bind(this));
        Events_ts_1.Events.dom(this.root, 'scroll', this.startIdleTimer.bind(this));
        // When page is focus without any event, it should not be idle.
        this.focus(this.startIdleTimer.bind(this));
    };
    IfVisible.prototype.on = function (event, callback) {
        Events_ts_1.Events.attach(event, callback);
        return this;
    };
    IfVisible.prototype.off = function (event, callback) {
        Events_ts_1.Events.remove(event, callback);
        return this;
    };
    IfVisible.prototype.setIdleDuration = function (seconds) {
        this.idleTime = seconds * 1000;
        this.startIdleTimer();
        return this;
    };
    IfVisible.prototype.getIdleDuration = function () {
        return this.idleTime;
    };
    IfVisible.prototype.getIdleInfo = function () {
        var now = +(new Date());
        var res;
        if (this.status === exports.STATUS_IDLE) {
            res = {
                isIdle: true,
                idleFor: now - this.idleStartedTime,
                timeLeft: 0,
                timeLeftPer: 100,
            };
        }
        else {
            var timeLeft = (this.idleStartedTime + this.idleTime) - now;
            res = {
                isIdle: false,
                idleFor: now - this.idleStartedTime,
                timeLeft: timeLeft,
                timeLeftPer: parseFloat((100 - (timeLeft * 100 / this.idleTime)).toFixed(2)),
            };
        }
        return res;
    };
    IfVisible.prototype.idle = function (callback) {
        if (callback) {
            this.on('idle', callback);
        }
        else {
            this.status = exports.STATUS_IDLE;
            Events_ts_1.Events.fire('idle');
            Events_ts_1.Events.fire('statusChanged', [{ status: this.status }]);
        }
        return this;
    };
    IfVisible.prototype.blur = function (callback) {
        if (callback) {
            this.on('blur', callback);
        }
        else {
            this.status = exports.STATUS_HIDDEN;
            Events_ts_1.Events.fire('blur');
            Events_ts_1.Events.fire('statusChanged', [{ status: this.status }]);
        }
        return this;
    };
    IfVisible.prototype.focus = function (callback) {
        if (callback) {
            this.on('focus', callback);
        }
        else if (this.status !== exports.STATUS_ACTIVE) {
            this.status = exports.STATUS_ACTIVE;
            Events_ts_1.Events.fire('focus');
            Events_ts_1.Events.fire('wakeup');
            Events_ts_1.Events.fire('statusChanged', [{ status: this.status }]);
        }
        return this;
    };
    IfVisible.prototype.wakeup = function (callback) {
        if (callback) {
            this.on('wakeup', callback);
        }
        else if (this.status !== exports.STATUS_ACTIVE) {
            this.status = exports.STATUS_ACTIVE;
            Events_ts_1.Events.fire('wakeup');
            Events_ts_1.Events.fire('statusChanged', [{ status: this.status }]);
        }
        return this;
    };
    IfVisible.prototype.onEvery = function (seconds, callback) {
        return new Timers_ts_1.default(this, seconds, callback);
    };
    IfVisible.prototype.now = function (check) {
        if (check !== undefined) {
            return this.status === check;
        }
        return this.status === exports.STATUS_ACTIVE;
    };
    return IfVisible;
}());
exports.IfVisible = IfVisible;
