var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$store.getters.token
    ? _c("login")
    : _c(
        "div",
        {
          class: [
            _vm.$store.getters.sidebar.opened ? "" : "miniMenu",
            "wrapper",
            "menuFixed",
          ],
          attrs: { id: "mainWrapper" },
        },
        [
          _c("sidebar", { ref: "menu" }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "content" } },
            [
              _c("mainbar"),
              _vm._v(" "),
              _c("history-menu"),
              _vm._v(" "),
              _c("router-view", { attrs: { id: "data" } }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }