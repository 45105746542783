"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Events = void 0;
var Events;
(function (Events) {
    var store = {};
    var setListener;
    function attach(event, callback) {
        if (!store[event]) {
            store[event] = [];
        }
        store[event].push(callback);
    }
    Events.attach = attach;
    function fire(event, args) {
        if (args === void 0) { args = []; }
        if (store[event]) {
            store[event].forEach(function (callback) {
                callback.apply(void 0, args);
            });
        }
    }
    Events.fire = fire;
    function remove(event, callback) {
        if (!callback) {
            delete store[event];
        }
        if (store[event]) {
            store[event] = store[event].filter(function (savedCallback) {
                return callback !== savedCallback;
            });
        }
    }
    Events.remove = remove;
    function dom(element, event, callback) {
        if (!setListener) {
            if (element.addEventListener) {
                setListener = function (el, ev, fn) {
                    return el.addEventListener(ev, fn, false);
                };
            }
            else if (typeof element.attachEvent === 'function') {
                setListener = function (el, ev, fn) {
                    return el.attachEvent("on" + ev, fn, false);
                };
            }
            else {
                setListener = function (el, ev, fn) {
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    return el["on" + ev] = fn;
                };
            }
        }
        return setListener(element, event, callback);
    }
    Events.dom = dom;
})(Events = exports.Events || (exports.Events = {}));
// export default Events;
