import variables from '@/../sass/_variables.scss'
import defaultSettings from '@/settings'

const {
    rowNumber,
    showSettings,
    tagsView,
    fixedHeader,
    sidebarLogo
} = defaultSettings

const state = {
    //theme: variables.theme,
    showSettings: showSettings,
    tagsView: tagsView,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo,
    rowNumber: rowNumber,
}

const mutations = {
    CHANGE_SETTING: (state, {
        key,
        value
    }) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}

const actions = {
    changeSetting({
        commit
    }, data) {
        commit('CHANGE_SETTING', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
