var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "date-picker",
        _vm.error ? "error" : "",
        _vm.mobile ? "mobile" : "",
      ],
    },
    [
      _c("input", {
        class: ["form-control", _vm.required ? "required" : ""],
        attrs: {
          type: "date",
          "append-to-body": false,
          type: _vm.typeDate,
          lang: "es",
          format: _vm.format,
          inputClass: "form-control",
          placeholder: _vm.format,
          disabled: _vm.disabled,
          editable: true,
          clearable: true,
          max: _vm.max_date,
          min: _vm.min_date,
        },
        domProps: { value: _vm.tmpValue },
        on: {
          input: function ($event) {
            _vm.tmpValue = $event.target.valueAsDate
          },
          change: _vm.handleChange,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }