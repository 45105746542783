import { login, logout, getInfo, refreshToken, ping } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

//import defaultPermissions from '@/defaultPermissions'
//const { localRoles, localPermissions } = defaultPermissions

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  avatar_notification: '',
  introduction: '',
  roles: [],// localRoles,
  permissions: [],// localPermissions,
  menu: [],
  time: 0,
};

const mutations = {
  SET_TOKEN: (state, token) => {
    if (state.token == token) return
    //alert('token ' + token)
    state.token = token;
    setToken(token)
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_AVATAR_NOTIFICATION: (state, avatar_notification) => {
    state.avatar_notification = avatar_notification;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_MENU: (state, menu) => {
    state.menu = menu;
  },
  SET_TIME: (state, time) => {
    state.time = time;
  }
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { name, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ name: name.trim(), password: password }).then(response => {
        console.log('login>..')

        if(response.token){
          commit('SET_TOKEN', response.token);
          setToken(response.token);
        }else if(response.data){
          commit('SET_TOKEN', response.data.token);
          setToken(response.data.token);
        }else{
          reject('sin datos')
        }
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  ping({ commit, state }) {
    return; //fix temporal a "Network Error"
    //console.log('ping', new Date(state.time * 1000))
    return new Promise((resolve, reject) => {
      /* 
      if (document.visibilityState === false) {
        commit('SET_TIME', Math.round((new Date()).getTime() / 1000, 0));
      }
      */
      if (new Date().diff(new Date(1000 * state.time)) > 10) {//diff in seconds
        if (state.token) {
          ping(state.time).then(response => {
            //console.log('pong', state, response)
            const data = response;
            /*
            const { data } = response;
            if (!data) {
              reject('Verification failed, please Login again.');
            }
            console.log('dataInfo', data)
            const { roles, permissions, name, avatar, introduction, menu } = data;
            console.log('dataInfo2', permissions, roles, name, avatar, introduction, menu)
            // roles must be a non-empty array
            if (!roles || roles.length <= 0) {
              reject('getInfo: roles must be a non-null array!');
            }
            commit('SET_ROLES', roles);
            commit('SET_PERMISSIONS', permissions);
            commit('SET_NAME', name);
            commit('SET_AVATAR', avatar);
            commit('SET_INTRODUCTION', introduction);
            resolve(data);
            */
            commit('SET_TIME', data);
            resolve(data)
          }).catch(error => {
            console.error('ping error', error)
            reject(error);
          });
        }
        else {
          reject('no esta logeado')
        }
      }
    });

  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.token) {

        getInfo(state.token).then(response => {
          console.log('getInfo>getInfo', response)
          const { data } = response;
          if (!data) {
            reject('Verification failed, please Login again.');
          }
          console.log('dataInfo', data)
          const { roles, permissions, name, avatar, avatar_notification, introduction, menu } = data;
          console.log('dataInfo2', permissions, roles, name, avatar, introduction, menu)
          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('getInfo: roles must be a non-null array!');
          }

          commit('SET_ROLES', roles);
          commit('SET_PERMISSIONS', permissions);
          commit('SET_NAME', name);
          commit('SET_AVATAR', avatar);
          commit('SET_AVATAR_NOTIFICATION', avatar_notification);
          commit('SET_INTRODUCTION', introduction);
          commit('SET_MENU', menu);
          resolve(data);

        }).catch(error => {
          console.log('getInfo>getInfoError', error.request, error)
          commit('SET_ROLES', []);
          commit('SET_PERMISSIONS', []);
          commit('SET_NAME', "");
          commit('SET_AVATAR', "");
          commit('SET_AVATAR_NOTIFICATION', "");
          commit('SET_INTRODUCTION', "");
          commit('SET_MENU', []);
          resolve();
          //reject(error);
        });
      } else {
        reject('no esta logeado')
      }
    });
  },
  // get user info
  refreshToken({ commit, state }) {

    console.log('getToken>..');
    return new Promise((resolve, reject) => {
      refreshToken(state.token).then(response => {
        console.log('getInfo>getToken');
        const { data } = response;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        const { token } = data;
        /*
        console.log('dataInfo gt', data)
        const { roles, permissions, key } = data;
        console.log('dataInfo gt2', permissions, roles,key)
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }
        commit('SET_ROLES', roles);
        commit('SET_PERMISSIONS', permissions);
        */
        commit('SET_TOKEN', token);
        setToken(data.token);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {

      console.log('logout')
      logout(state.token).then(() => {
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);

        removeToken();
        resetRouter();
        resolve();
      }).catch(error => {

        console.log('logout err')
        reject(error);
      });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      console.log('resetToken')

      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      commit('SET_PERMISSIONS', []);
      removeToken();
      resolve();
    });
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token';

      commit('SET_TOKEN', token);
      setToken(token);

      const { roles } = await dispatch('getInfo');

      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true });

      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
