import request from '@/utils/request'

import { getToken, setToken, removeToken } from "@/utils/auth";

export function login(data) {
  console.log('login', data)
  return request({
    method: 'post',
    url: '/user/login',
    data:data,
    cancelToken:fnCancelRequest('login')
  } )

  /*
  .then((data) => {
    console.log("login ok", data);
    let token = data.data.token;
    setToken(token);
    return getInfo(token).then(data => {
      console.log("info", data);
      return data;
    });
  })
  .catch(e => {
    console.log("login error", e);
    throw (e)
  });
  */
}

export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token },
    cancelToken:fnCancelRequest('getInfo')
  } )
}

export function ping(t) {
  return request({
    url: '/user/ping',
    method: 'get',
    params: { t },
    cancelToken:fnCancelRequest('p')
  })
}

export function refreshToken(token) {
  return request({
    url: '/user/info',
    method: 'delete',
    params: { token },
    cancelToken:fnCancelRequest('refreshT')
  })
}

export function logout() {
  console.log('logout2')
  return request({
    url: '/user/logout',
    method: 'post'
  })
    .then((r) => {
      removeToken()
    })
    .catch((e) => {
      removeToken()
    });
}

export function update_profile(data) {
  return request({
    url: '/user/update',
    method: 'put',
    data: data,
    cancelToken:fnCancelRequest('updateP')
  })
}