<template>
  <div class="table_wrapper">
  <div ref="floatHeader"></div>
<table style="width:100%;min-width:200px;">
<thead ref="tableHeader">
    <tr>
        <th
            v-for="head in columns"
              v-if="head.visible"
              :class="head.class"
              :min-width="head.width+'px'"
              :width="head.width*100/totalWidth"
            >{{ head.text }}
          </th>
          <th class="td_accion" min-width="100px" width="1px">Acciones</th>
      </tr>
  </thead>
   <tbody>
     <template  >
       <tr v-if="multiple!=true" v-for="(item,key) in data">
            <template v-for="head in columns" v-if="head.visible">
             <td v-if="head.html" v-html="format(head,item,key)" v-bind:class="[item.en_uso_actual?'activo':'']"></td>
            <td v-bind:class="[item.en_uso_actual?'activo':'']" v-else>{{format(head,item,key)}}
            </td>
            </template>
            <td class="td_accion">
              <slot name="actions" v-bind:item="item" v-bind:idx="key" >&nbsp;</slot>
            </td>
</tr>

      <tr v-else v-for="(mitem,key) in data"> 
          <template v-for="(item,skey) in mitem">
              <template v-if="skey==0">
                <template v-for="head in columns" v-if="head.visible">
                  <td
                    v-if="head.multiple"
                    :class="[(item && item.carrera_publica)?'carrera_publica':'experiencia_laboral']">
                    <span v-if="head.html" v-html="format(head,item,key)"></span>
                    <span v-else>{{format(head,item,key)}}</span>
                  </td>
                  <td v-else v-bind:rowspan="mitem.length">
                    <span v-if="head.html" v-html="format(head,item,key)"></span>
                    <span v-else>{{format(head,item,key)}}</span>
                  </td>
                </template>
                <td v-bind:rowspan="mitem.length">
                  <slot name="actions" v-bind:item="item"  v-bind:idx="item">&nbsp;</slot>
                </td>
              </template>
              <template v-else>
                <template v-for="head in columns" v-if="head.visible">
                  <td
                    v-if="head.multiple"
                    :class="item.class">
                    <span v-if="head.html" v-html="format(head,item,key)"></span>
                    <span v-else>{{format(head,item,key)}}</span>
                  </td>
                  <!--<td v-else></td>-->
                </template>
                <!--<th></th>-->
              </template>
          </template> 
            </tr>
          </template> 
  </tbody>
  <tfoot>
  </tfoot>

<slot></slot>
</table>
</div>
</template>

<script>
export default {
  components:{
  },
  props: {
    data: {
      type: Array | Object,
      default: function () {
        return [];
      }
    },
    columns: {
      type: Array | Object,
      default: function () {
        return [];
      },
      },
    multiple: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
  },
  data() {
    return {
      fecha: String(this.value).replace(/-/g, '\/')
      // fecha: null
    };
  },
  mounted(){
    //window.self=this;
    this.$refs.floatHeader.innerHTML=''
    
  },
  created(){
  },
  computed:{
    totalWidth:function(){
      let total=100;
      for(let i=0;i<this.columns;i++){
        if(!this.columns.width)
        total+=100;
        else
        total+=this.columns.width;
      }
      return total;
    }
  },
  methods:{
        format(column, item) {
      if (column.field) {
        if (column.fn) {
          return column.fn(item[column.field],item);
        } else {
          return item[column.field];
        }
      } else {
        if (column.fn) {
          return column.fn(item,column);
        }
      }
    },
  }
}

</script>
<style scoped>
  table{
    table-layout:auto;
  }
  td,th {
  }
  .td_accion{
    white-space: nowrap;
  }


/*
      .table_wrapper {
        overflow-y: auto;
        height: 106px;
      }
      .table_wrapper thead th {
        position: sticky;
        top: 0;
      }
      table {
        border-collapse: collapse;
        width: 100%;
      }
      th,
      td {
        padding: 8px 16px;
        border: 1px solid #ccc;
      }
      th {
        background: #eee;
      }
      */

/*
       .table_wrapper {
        table-layout: fixed;
        border-collapse: collapse;
      }
      .table_wrapper tbody {
        display: block;
        width: 100%;
        overflow: auto;
        height: 100vh;
      }
      .table_wrapper thead tr {
        display: block;
      }
      .table_wrapper thead {
        background: black;
        color: #fff;
      }
      .table_wrapper th,
      .table_wrapper td {
        padding: 5px;
        text-align: left;
        width: 200px;

      }
      */
</style>