<template >
  <login v-if="!$store.getters.token"></login>
  <div
    id="mainWrapper"
    v-else
    :class="[ $store.getters.sidebar.opened?'':'miniMenu', 'wrapper','menuFixed']"
  >
    <!-- <div class="wrapper"> -->
    <sidebar ref="menu"></sidebar>
    <!-- Page Content  -->
    <div id="content">
      <mainbar></mainbar>
      <history-menu></history-menu>
      <router-view id="data"></router-view>
    </div>
  </div>
</template>
<script>
const Menu = () => import("@/components/otros/Menu");
const Mainbar = () => import("@/components/otros/MainBar");
const Login = () => import("@/views/login/index");
const HistoryMenu = () => import("@/components/otros/HistoryMenu");

export default {
  components: {
    sidebar: Menu,
    Login,
    Mainbar,
    HistoryMenu
  },
  created() {
    ////  console.log("process.env.APP_NAME", process.env.APP_NAME, process.env);
  },
  data() {
    return {
      interval: null
    };
  },
  methods: {},
  mounted: function() {
    this.$store.dispatch("user/ping");
    this.interval = setInterval(
      function() {
        this.$store.dispatch("user/ping").catch(e => {});
      }.bind(this),
      10000
    );
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  }
};
</script>
<style lang="css">
#app.menuFixed .navbar-main {
}
#app.menuFixed #content {
}
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#content {
  width: 100%;
  padding: 0px;
  margin:0px;
  /*
  padding: 10px;
  margin:10px;
  margin-top:69px;
  */
}

.fade-enter-active,
.fade-leave-active {
  /* transition: opacity 1.5s; */
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


#data {
  border:solid 1px #999;
  background:#fff;
  padding-bottom:5px;
  padding-top:5px;
  min-height:60vh;
}
/*
.btn-toolbar,
 #data>* {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes row {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}
*/
</style>
