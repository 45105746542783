import router from './router'
import store from './store'
import { Message } from '@/utils/messages'
//import NProgress from 'nprogress' // progress bar
//import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

//NProgress.configure({ showSpinner: false }) // NProgress Configuration


const whiteList = ['/login', '/auth-redirect', '/401', '/404'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {

  document.title = getPageTitle(to.meta.title)
  const hasToken = getToken()
  console.log('startRoute', to, from)

  if (whiteList.includes(to.path)) {
    //console.log('startRoute', 'continue')
    next();
  }
  else {
    if (!store.getters.name) {
      await store.dispatch('user/getInfo')
    }
    else {
      console.error('name', store.getters.name)
    }
    if (hasToken) {
      console.log('startRoute', 'continue with token')
      if (!store.getters.name) {
        await store.dispatch('user/getInfo').then((data) => {
        })
      }
      if (to.meta) {

        console.log('startRoute', 'continue validating')
        let access = true;
        if ((to.meta.permissions && to.meta.permissions.length > 0) || (to.meta.roles && to.meta.roles.length > 0)) {
          access = false
          if (to.meta.permissions.length > 0) {
            {
              console.log('startRoute', 'validating permission', store.getters.permissions, to.meta.permissions)

              let permissions = store.getters.permissions;
              for (let i = 0; i < permissions.length; i++) {
                if (to.meta.permissions.includes(permissions[i])) {
                  access = true;
                  break;
                }
              }
            }
          }
          if (!access && to.meta.roles.length > 0) {
            {
              console.log('startRoute', 'validating roles', store.getters.roles, to.meta.roles)
              let roles = store.getters.roles;
              for (let j = 0; j < roles.length; j++) {
                if (to.meta.roles.includes(roles[j])) {
                  access = true;
                  break;
                }

              }
            }
          }
        }
        if (access) {
          next()
        }
        else {
          //next(`/401`) 
          next(`/401?redirect=${to.path}`)
        }
      }
      else {
        console.log('startRoute', 'continue with token')
        next()
      }
    }
    else {
      //alert('login')
      console.log('/login <--- ', to.path)
      next(`/redirect=${to.path}`)
      //next()
    }
  }
})
/*
router.afterEach(() => {
  console.log('endRoute')
})
*/
/*
router.beforeEach(async (to, from, next) => {
  // start progress bar
  //NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  //console.log('beforeEach', to)
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      console.log('/login ---> ', to.path)

      //next({ path: '/' })

      //NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        console.log('hasRoles')
        next()
      } else {
        console.log('getUserInfo')
        try {
          console.log('...getInfo')
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles } = await store.dispatch('user/getInfo')

          //// generate accessible routes map based on roles
          //const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          //// dynamically add accessible routes
          //router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          console.log('...next', to)
          next({ ...to, replace: true })
        } catch (error) {
          console.log('error re-login?', error)
          if (error.message) {

            switch (error.message) {
              case "Token has expired":
                //await store.dispatch('user/refreshToken')
               // alert('token expired')
                break;
              case "The token has been blacklisted":
                await store.dispatch('user/resetToken')
                //alert("The token has been blacklisted")
                break;
              default:
                throw (error);
            }
          }
          next()

          //next(`/login?redirect=${to.path}`)
          // remove token and go to login page to re-login

          // await store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)

          //NProgress.done()
        }
      }
    }
  } else {
    // has no token
    //console.log('no token', to)
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      //alert('redirect 1')
      next()
    } else {
      //alert('redirect 2')
      // other pages that do not have permission to access are redirected to the login page.
      //next(`/login?redirect=${to.path}`)
      next()
      //NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  //NProgress.done()
})


*/