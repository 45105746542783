<template>
    <div class="el-time form-control" >
<svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" alt="icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-clock b-icon bi"><g><path fill-rule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm8-7A8 8 0 110 8a8 8 0 0116 0z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M7.5 3a.5.5 0 01.5.5v5.21l3.248 1.856a.5.5 0 01-.496.868l-3.5-2A.5.5 0 017 9V3.5a.5.5 0 01.5-.5z" clip-rule="evenodd"></path></g></svg> 
        <select
            v-model="lhour"
            ref="rhour"
            v-on:keydown="keyHour($event)"
            v-if="ampm"
        >
            <option v-for="(k, i) in l1a12" :value="k">{{ k }}</option>
        </select>
        <select
            v-model="lhour"
            ref="rhour"
            v-on:keydown="keyHour($event)"
            v-else
        >
            <option v-for="(k, i) in l0a24" :value="k">{{ k }}</option>
        </select>
        :
        <select
            v-model="lminute"
            ref="rminute"
            v-on:keydown="keyMinute($event)"
        >
            <option v-for="(k, i) in l0a60" :value="k">{{ k }}</option>
        </select>
        :
        <select
            v-model="lsecond"
            ref="rsecond"
            v-on:keydown="keySecond($event)"
        >
            <option v-for="(k, i) in l0a60" :value="k">{{ k }}</option>
        </select>
&nbsp;
        <select v-model="lampm" v-if="ampm" ref="rampm" v-on:keydown="keyAmPm($event)">
            <option value="am">AM</option>
            <option value="pm">PM</option>
        </select>
    </div>
</template>
<script>
export default {
    props: {
        value: String | Date,
        ampm: Boolean
    },
    data() {
        return {
            l1a12: [],
            l0a24: [],
            l0a60: [],
            lhour: 0,
            lminute: 0,
            lsecond: 0,
            lampm: "am"
        };
    },
    created() {
        let i;

        for (i = 1; i <= 12; i++) {
            this.l1a12[i] = ("00" + i).substr(-2);
        }
        for (i = 0; i < 24; i++) {
            this.l0a24[i] = ("00" + i).substr(-2);
        }
        for (i = 0; i < 60; i++) {
            this.l0a60[i] = ("00" + i).substr(-2);
        }
    },
    computed: {
        chour() {
            if (this.ampm) {
                if (this.lampm == "pm") {
                    let tmp = 12 + 1 * this.lhour;
                    if (tmp >= 24) tmp = 0;
                    return tmp;
                }
            }
            return this.lhour;
        },
        lTimeStr() {
            return this.chour + ":" + this.lminute + ":" + this.lsecond;
        },
        lTime() {
            return new Date(0, 0, 0, this.chour, this.lminute, this.lsecond);
        }
    },
    watch: {
        lampm(nv, ov) {
            if (nv == ov) return;
            this.$emit("input", this.lTimeStr);
        },
        lhour(nv, ov) {
            if (nv - ov == 0) return;

            this.$emit("input", this.lTimeStr);
        },
        lminute(nv, ov) {
            if (nv - ov == 0) return;
            this.$emit("input", this.lTimeStr);
        },
        lsecond(nv, ov) {
            if (nv - ov == 0) return;
            this.$emit("input", this.lTimeStr);
        },
        value(nv,ov) {
            if(nv==ov) return
            // console.log(nv)
            if (this.ampm) {
                let tmp;
                if (typeof nv == "string") {
                    if(nv.length>5) nv=nv.substr(0,5);
                    let b = nv.split(":");
           
                     if(b.length == 1) b[1]='00'
                    if(b.length == 2) b[2]='00'
                    if (b.length == 3) {
                        if (isNaN(b[0])) b[0] = b[0].split(/[^0-9]/).pop();
                        if (isNaN(b[1])) b[1] = b[1].split(/[^0-9]/).pop();
                        if (isNaN(b[2])) b[2] = b[2].split(/[^0-9]/).pop();
                        tmp = ("00" + b[0]).substr(-2);
                        if (tmp == 0) tmp = "24";
                        if (tmp > 12) {
                            this.lhour = ("00" + (tmp - 12)).substr(-2);
                            this.lampm = "pm";
                        }

                        this.lminute = ("00" + b[1]).substr(-2);
                        this.lsecond = ("00" + b[2]).substr(-2);
                        return;
                    }
                    
                } else if (nv instanceof Date) {
                    tmp = nv.getHours();
                    if (tmp == 0) tmp = "24";
                    if (tmp > 12) {
                        this.lhour = ("00" + (tmp - 12)).substr(-2);
                        this.lampm = "pm";
                    }

                    this.lminute = nv.getMinutes();
                    this.lsecond = nv.getSeconds();
                    return;
                }
                this.lhour = "01";
                this.lminute = "00";
                this.lseconds = "00";
                this.lampm = "am";
            } else {
                if (typeof nv == "string") {
                    let b = nv.split(":");
                    if(b.length == 1) b[1]='00'
                    if(b.length == 2) b[2]='00'
                    if (b.length == 3) {
                        if (isNaN(b[0])) b[0] = b[0].split(/[^0-9]/).pop();
                        if (isNaN(b[1])) b[1] = b[1].split(/[^0-9]/).pop();
                        if (isNaN(b[2])) b[2] = b[2].split(/[^0-9]/).pop();
                        this.lhour = ("00" + b[0]).substr(-2);
                        this.lminute = ("00" + b[1]).substr(-2);
                        this.lsecond = ("00" + b[2]).substr(-2);
                        return;
                    }
                } else if (nv instanceof Date) {
                    this.lhour = nv.getHours();
                    this.lminute = nv.getMinutes();
                    this.lsecond = nv.getSeconds();
                    return;
                }
                this.lhour = "00";
                this.lminute = "00";
                this.lseconds = "00";
            }
        }
    },
    methods: {
        keyHour(e) {
            let tmp;
            switch (e.key) {
                case "Backspace":
                    this.lhour = ("0" + this.lhour).substring(0, 2);
                    break;
                case "ArrowDown":
                    if (this.ampm) {
                        if (this.lhour >= 12) {
                            this.lhour = "01";
                            e.preventDefault();
                            return false;
                        }
                    } else {
                        if (this.lhour >= 23) {
                            this.lhour = "00";
                            e.preventDefault();
                            return false;
                        }
                    }
                    break;
                case "ArrowUp":
                    if (this.ampm) {
                        if (this.lhour <= 1) {
                            this.lhour = "12";
                            e.preventDefault();
                            return false;
                        }
                    } else {
                        if (this.lhour <= 0) {
                            this.lhour = "23";
                            e.preventDefault();
                            return false;
                        }
                    }
                    break;
                case "ArrowRight":
                    this.$refs.rminute.focus();
                    e.preventDefault();
                    return false;
                    break;
                case "ArrowLeft":
                    e.preventDefault();
                    return false;
                    break;
                default:
                    if (e.key >= 0 && e.key <= 9) {
                        tmp = (this.lhour + e.key).substr(-2);
                        //  console.log(this.lhour, tmp);

                        if (this.ampm) {
                            if (tmp < 12) this.lhour = tmp;
                            else this.lhour = ("00" + e.key).substr(-2);
                        } else {
                            if (tmp < 24) this.lhour = tmp;
                            else this.lhour = ("00" + e.key).substr(-2);
                        }
                        e.preventDefault();
                        return false;
                        break;
                    }
                    //  console.log(e);
            }
        },
        keyMinute(e) {
            let tmp;
            switch (e.key) {
                case "Backspace":
                    this.lminute = ("0" + this.lminute).substring(0, 2);
                    break;
                case "ArrowDown":
                    if (this.lminute >= 59) {
                        this.lminute = "00";
                        e.preventDefault();
                        return false;
                    }
                    break;
                case "ArrowUp":
                    if (this.lminute <= 0) {
                        this.lminute = "59";
                        e.preventDefault();
                        return false;
                    }
                    break;
                case "ArrowRight":
                    this.$refs.rsecond.focus();
                    e.preventDefault();
                    return false;
                    break;
                case "ArrowLeft":
                    this.$refs.rhour.focus();
                    e.preventDefault();
                    return false;
                    break;
                default:
                    if (e.key >= 0 && e.key <= 9) {
                        tmp = (this.lminute + e.key).substr(-2);
                        //  console.log(this.lminute, tmp);

                        if (tmp < 60) this.lminute = tmp;
                        else this.lminute = ("00" + e.key).substr(-2);

                        e.preventDefault();
                        return false;
                        break;
                    }
                    //  console.log(e);
            }
        },
        keySecond(e) {
            let tmp;
            switch (e.key) {
                case "KeyV":
                    break;
                case "Backspace":
                    this.lsecond = ("0" + this.lsecond).substring(0, 2);
                    break;
                case "ArrowDown":
                    if (this.lsecond >= 59) {
                        this.lsecond = "00";
                        e.preventDefault();
                        return false;
                    }
                    break;
                case "ArrowUp":
                    if (this.lsecond <= 0) {
                        this.lsecond = "59";
                        e.preventDefault();
                        return false;
                    }

                    break;
                case "ArrowRight":
                    e.preventDefault();
                    this.$refs.rampm.focus();
                    return false;
                    break;
                case "ArrowLeft":
                    e.preventDefault();
                    this.$refs.rminute.focus();
                    return false;
                    break;
                default:
                    if (e.key >= 0 && e.key <= 9) {
                        tmp = (this.lsecond + e.key).substr(-2);
                        //  console.log(this.lsecond, tmp);

                        if (tmp < 60) this.lsecond = tmp;
                        else this.lsecond = ("00" + e.key).substr(-2);

                        e.preventDefault();
                        return false;
                        break;
                    }
                    //  console.log(e);
            }
        },
        keyAmPm(e) {
            switch (e.key) {
                case "ArrowDown":
                    if (this.lampm == "pm") {
                        this.lampm = "am";
                        e.preventDefault();
                        return false;
                    }
                    break;
                case "ArrowUp":
                    if (this.lampm == "am") {
                        this.lampm = "pm";
                        e.preventDefault();
                        return false;
                    }

                    break;
                case "ArrowRight":
                    e.preventDefault();
                    return false;
                    break;
                case "ArrowLeft":
                    e.preventDefault();
                    this.$refs.rsecond.focus();
                    return false;
                    break;
                default:
            }
        }
    }
};
</script>
<style>
.el-time{
  padding:0;
}
  .el-time>svg {
      margin-right: 2px;
      margin-left: 5px;
  }
  .el-time>select::-ms-expand {
    display: none;
  }
  .el-time>select{
    -webkit-appearance: none;
    appearance: none /*menulist*/ !important;
    border:none;
    max-width: 2em;
    width: 4em;
   height: 1em;
    text-align: center;
    line-height: 1em;

    text-align: center;
    margin-left: -0.4em;
    background: none;
    margin-right: -0.4em;
 
  }
</style>