export function MessageBox() {
    return {

        confirm: function (msg, options) {
            return confirm(msg);
            //return prompt(msg);
        },
    }
}
export const Notification = {

    /*
                  Notification.error({
                      title: 'Error de validación',
                      dangerouslyUseHTMLString: true,
                      message: 'message'
                  })
*/
    confirm: function (msg, options) {
        return confirm(msg);
        //return prompt(msg);
    },
    error: function (obj) {
        console.error('Error: ' + obj.message)

    },
    info: function (obj) {
        console.warn('Info: ' + obj.message)
    },
    success: function (obj) {
        console.info('OK: ' + obj.message)
    }

}
export function Message(options) {
    return alert(options.message);
    //return prompt(msg);

}
